import React from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import Video from "@components/Video";
import SEO from "@components/SEO";
import Layout from "@components/Layout";

import styles from "@cssmodules/service.module.scss";

export default function Service() {
    return (
        <Layout>
            <main id="main-content" className={styles.main}>
                <SEO title="Layanan Gratis" />
                <Container>
                    <Row className="flex-column-reverse flex-md-row justify-content-between align-items-center mb-5">
                        <Col xs={12} md={6}>
                            <div className={styles.video}>
                                <Video
                                    quality="sd"
                                    title="Layanan Gratis"
                                    link="https://www.youtube.com/embed/vQw7ldUsbK4"
                                />
                            </div>
                        </Col>
                        <Col xs={12} md={6} className="pl-md-5">
                            <h2 className={styles.title}>
                                6 Layanan Gratis Hanya Untuk Anda
                            </h2>
                            <p className={styles.subtitle}>
                                Selalu memudahkan dan menguntungkan anda
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card
                                className="border-0"
                                style={{
                                    backgroundColor: "#eee",
                                    borderRadius: "1.5rem",
                                }}
                            >
                                <Card.Body className="p-xs-3 p-md-5">
                                    <div className={styles.serviceList}>
                                        <h3>1. Kunjungan GRATIS</h3>
                                        <p>
                                            Bagi klien yang berkantor di wilayah
                                            Jakarta dan jumlah pesanan rompi 50
                                            pcs, kami melayani kunjungan ke
                                            kantor klien selama jam kerja,
                                            setelah waktu dan tempat yang
                                            disepakati ditentukan.
                                        </p>
                                    </div>
                                    <div className={styles.serviceList}>
                                        <h3>2. Pengukuran GRATIS</h3>
                                        <p>
                                            Untuk memastikan bahwa rompi yang
                                            dipesan memiliki ukuran yang tepat
                                            dengan tubuh klien, kami menyediakan
                                            layanan dalam bentuk mengirimkan
                                            pakaian sampel untuk pengukuran,
                                            ukuran S, M, L, XL, XXL.
                                        </p>
                                        <p>
                                            Caranya, klien terlebih dahulu
                                            mentransfer jaminan senilai Rp.
                                            300.000 ke kami, maka kami akan
                                            mengirimkan satu set rompi untuk
                                            pengukuran. Jika pengukuran telah
                                            diselesaikan oleh klien, klien
                                            mengirim kembali rompi ukuran
                                            sampel. Setelah pakaian ukuran
                                            sampel telah sampai pada kami, kami
                                            akan mengembalikan jaminan 100%.
                                            Biaya pengiriman ke kantor klien
                                            gratis dan biaya pengiriman kembali
                                            ke kami ditanggung oleh klien.
                                        </p>
                                    </div>
                                    <div className={styles.serviceList}>
                                        <h3>3. Desain GRATIS</h3>
                                        <p>
                                            Klien tidak harus mempunyai desain
                                            rompi sebelum memesan. Jika klien
                                            tidak mempunyai gambar mengenai
                                            desain rompi yang akan dipesan, kami
                                            punya banyak desain rompi yang dapat
                                            menjadi gambaran awal, dari rompi
                                            yang ingin Anda buat.
                                        </p>
                                        <p>
                                            Jika klien mengalami kesulitan dalam
                                            mendesain rompi tim desain kami siap
                                            membantu merancang rompi yang ingin
                                            Anda buat. Waktu pemrosesan 1-2 hari
                                            kerja.
                                        </p>
                                    </div>
                                    <div className={styles.serviceList}>
                                        <h3>4. Sampel Bordir GRATIS</h3>
                                        <p>
                                            Logo sangat penting untuk identitas
                                            perusahaan. Ukuran logo, warna logo
                                            dan proporsi bentuk logo adalah hal
                                            yang sangat kami perhatikan. Oleh
                                            karena itu, kami selalu menyediakan
                                            sampel bordir untuk akreditasi
                                            klien, sebelum produksi massal.
                                            Waktu pemrosesan 3-5 hari kerja.
                                        </p>
                                    </div>
                                    <div className={styles.serviceList}>
                                        <h3>5. Sampel Baju GRATIS</h3>
                                        <p>
                                            Untuk pesanan di atas 50 pcs, kami
                                            membuat sampel rompi untuk menjadi
                                            patokan untuk pekerjaan massal.
                                            Biaya sampel dan pengiriman gratis.
                                            Jika klien setuju dengan sampel
                                            rompi, maka klien mengembalikan
                                            rompi sampel yang telah
                                            terakreditasi untuk menjadi tolak
                                            ukur.
                                        </p>
                                        <p>
                                            Jika masih ada revisi dari pakaian
                                            sampel yang dibuat, kami akan
                                            merevisi rompi sampel sesuai dengan
                                            keinginan klien, tanpa biaya
                                            tambahan, selama mereka tidak
                                            membuat rompi baru.
                                        </p>
                                        <p>
                                            Namun, apabila sampel kedua direvisi
                                            / rompi sampel, harus dibuat ulang,
                                            apakah karena perubahan bahan atau
                                            perubahan warna, itu akan dikenakan
                                            biaya 3x dari harga yang disepakati.
                                            Waktu pemrosesan maksimum 1 minggu.
                                        </p>
                                    </div>
                                    <div className={styles.serviceList}>
                                        <h3>6. Pengiriman GRATIS</h3>
                                        <p>
                                            Untuk nilai pesanan di atas
                                            Rp5.000.000, biaya ongkos kirim
                                            rompi yang dipesan, untuk wilayah
                                            Jakarta gratis. Dan untuk daerah di
                                            luar Jakarta, dapatkan diskon Rp
                                            100.000. Pengiriman rompi yang telah
                                            selesai dilakukan sehari setelah
                                            kami menerima pembayaran secara
                                            penuh.
                                        </p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </main>
        </Layout>
    );
}
